var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-5 py-5",attrs:{"flat":"","outlined":""}},[_c('v-row',[_c('v-col',[_c('v-radio-group',{attrs:{"row":""},on:{"change":function($event){return _vm.ordenaProcs(_vm.filtroLabel)}},model:{value:(_vm.filtroLabel),callback:function ($$v) {_vm.filtroLabel=$$v},expression:"filtroLabel"}},[_c('v-radio',{attrs:{"color":"indigo darken-4","label":"Todos","value":"Todos"}}),_c('v-radio',{attrs:{"color":"indigo darken-4","label":"Comuns","value":"Processo Comum"}}),_c('v-radio',{attrs:{"color":"indigo darken-4","label":"Específicos","value":"Processo Específico"}}),_c('v-radio',{attrs:{"color":"indigo darken-4","label":"Relacionados","value":"Pré-Selecionado"}})],1)],1)],1),_c('v-data-table',{staticClass:"ma-1",attrs:{"items":_vm.listaProcs.procs,"headers":_vm.headers,"item-key":"chave","footer-props":_vm.procsFooterProps,"search":_vm.filtro},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{style:({
          backgroundColor: props.item.edited
            ? '#BBDEFB'
            : props.item.preSelected > 0
            ? '#FFECB3'
            : 'transparent',
        })},[_c('td',[_vm._v(_vm._s(props.item.codigo))]),_c('td',[_vm._v(_vm._s(props.item.titulo))]),_c('td',[(
              props.item.dono &&
              props.item.entidades &&
              props.item.entidades.length > 0
            )?_c('span',_vm._l((props.item.entidades),function(e,i){return _c('span',{key:i},[(e.dono)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":"indigo darken-4","outlined":"","label":"","x-small":""}},[_vm._v(" "+_vm._s(e.sigla)+" ")]):_vm._e()],1)}),0):_vm._e()]),_c('td',[(
              props.item.participante &&
              props.item.entidades &&
              props.item.entidades.length > 0
            )?_c('span',_vm._l((props.item.entidades),function(e,i){return _c('span',{key:i},[(e.participante != 'NP')?_c('v-chip',{staticClass:"ml-1",attrs:{"color":"indigo darken-4","outlined":"","label":"","x-small":""}},[_vm._v(" "+_vm._s(e.sigla)+"-"+_vm._s(e.participante)+" ")]):_vm._e()],1)}),0):_vm._e()]),_c('td',[_c('v-btn',{staticClass:"ma-2 clav-linear-background white--text",attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.selecionaParticipacoes(props.item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.selecionaResponsabilidadesIcon))])],1),_c('v-btn',{staticClass:"ma-2 clav-linear-background white--text",attrs:{"small":"","rounded":""},on:{"click":function($event){return _vm.editaBlocoDescritivo(props.item)}}},[_c('v-icon',[_vm._v(_vm._s(props.item.descriptionEdited ? _vm.editadoIcon : _vm.editaBlocoDescritivoIcon))])],1)],1)])]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" Resultados: "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}],null,true)}),_c('v-row',{staticClass:"ml-1",attrs:{"wrap":""}},[_c('v-col',[_vm._v(" Nº de processos selecionados: "+_vm._s(_vm.listaProcs.numProcessosSelecionados)+" ")]),_c('v-col',[_vm._v(" Nº de processos a selecionar: "+_vm._s(_vm.processosPreSelecionados)+" ")])],1),(_vm.selecionaResponsabilidades)?_c('Selresponsabilidade',{attrs:{"p":_vm.procSel},on:{"selecionadas":function($event){return _vm.selectProc($event)},"cancelada":_vm.selecaoCancelada}}):_vm._e(),(_vm.editaBlocoDescritivoFlag)?_c('EditDescritivo',{attrs:{"p":_vm.procSel},on:{"editado":function($event){return _vm.blocoDescritivoEditado($event)},"cancelado":function($event){return _vm.blocoDescritivoCancelado($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }