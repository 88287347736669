var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"overflow":"auto","width":"100%"}},[_c('v-data-table',{staticClass:"elevation-1 mytable",staticStyle:{"background-color":"#fafafa"},attrs:{"flat":"","headers":_vm.headers,"items":_vm.classes,"footer-props":_vm.footer_props,"search":_vm.search,"items-per-page":5,"show-expand":"","single-expand":"","item-key":"codigo"},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [(item.tipo == 'Série')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_sr}}):(item.tipo == 'Subsérie')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_ssr}}):(item.tipo == 'N1')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N1}}):(item.tipo == 'N2')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N2}}):(item.tipo == 'N3')?_c('img',{staticStyle:{"width":"23px","height":"30px"},attrs:{"src":_vm.svg_N3}}):_c('i',[_vm._v(_vm._s(item.tipo))])]}},{key:"item.df",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(!_vm.classes.some(function (e) { return e.eFilhoDe == item.codigo; }) ? item.df : ''))]}},{key:"item.pca",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(!_vm.classes.some(function (e) { return e.eFilhoDe == item.codigo; }) ? item.pca : ''))]}},{key:"item.editar",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(true)?_c('v-icon',_vm._g({on:{"click":function($event){return _vm.editarClasse(item)}}},on),[_vm._v("edit")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Clique para editar")])])]}},{key:"item.completo",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [((item.tipo == 'Série' && (item.eFilhoDe == null || (item.temDF && !!!item.children[0]))) || (item.tipo == 'Subsérie' && (item.eFilhoDe == null || item.temDF)) || (item.eFilhoDe == null &&
                  (item.tipo == 'N2' || item.tipo == 'N3')))?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v("report")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Classe incompleta")])])]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.tipo != 'Série' && item.tipo != 'Subsérie')?_c('AreaOrganica',{attrs:{"classe":item}}):_c('SerieSubserie',{attrs:{"classe":item,"formaContagem":_vm.formaContagem,"classes":_vm.classes}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }